.mobileHide {
    display: none;
}

.rebaeLogo {
    margin-bottom: 0;
}

.rebaeLogoWhite {
    color: white;
}

.rebaeLogoRed {
    color: #981b16;
}

@media (min-width: 550px) {
    .mobileHide {
        display: block;
    }
}

.pageForeground {
    position: relative;
    z-index: 1;
}

.pageBackground {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    z-index: 0;
    background-color: #5c5557;
}

.pageBgRemainder {
    flex-grow: 1;
    flex-shrink: 1;
}

.rebaeNavBar {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #262324;
    font-family: "Quicksand";
    color: #981b16;
    -webkit-box-shadow: 0px 5px 20px 5px rgba(0,0,0,0.2); 
    box-shadow: 0px 5px 20px 5px rgba(0,0,0,0.2);
}

.rebaeHeadingPanel {
    margin-top: 3rem;
    padding: 3rem;
    text-align: center;
    background-color: #262324;
    border-radius: 1rem;
    font-family: "Quicksand";
    color: #733d8f;
    border: 5px solid white;
    -webkit-box-shadow: 0px 5px 20px 5px rgba(0,0,0,0.2); 
    box-shadow: 0px 5px 20px 5px rgba(0,0,0,0.2);
}

.rebaeHeadingPanel h3 {
    font-size: 3rem;
    line-height: 1;
    color: #733d8f;
    margin-bottom: 0;
}

.rebaeHeadingPanel h2 {
    font-size: 6rem;
    color: white;
    margin-bottom: 0;
    line-height: 1;
}

.rebaeHeadingPanel h2 > small {
    font-size: 2rem;
    line-height: 1;
    color: #981b16;
}

.rebaeHeadingPanel h4 {
    font-size: 1rem;
    line-height: 1;
    color: #981b16;
}

.rebaeHeadingPanel p:last-child {
    margin-bottom: 0;
}

.rebaseDashPanel {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 3rem 3rem 2rem 3rem;
    text-align: center;
    background-color: #262324;
    border-radius: 1rem;
    font-family: "Quicksand";
    color: #2547ab;
    border: 5px solid white;
    -webkit-box-shadow: 0px 5px 20px 5px rgba(0,0,0,0.2); 
    box-shadow: 0px 5px 20px 5px rgba(0,0,0,0.2);
}

.rebaseDashPanel h3 {
    font-size: 3rem;
    line-height: 1;
    color: #733d8f;
    margin-bottom: 0.5;
}

.rebaseDashPanel h2 {
    font-size: 6rem;
    color: white;
    margin-bottom: -0.8rem;
    line-height: 1;
}

.rebaseDashPanel h2 > small {
    font-size: 2rem;
    line-height: 1;
    color: #981b16;
}

.rebaseDashPanel h4 {
    font-size: 2rem;
    line-height: 1;
    color: #981b16;
}